<template>
  <div>
    <div class="image_warp">
      <el-image style="width: 100%;"
                v-for="(item,idx) in imageList"
                :key="idx"
                :z-index="idx"
                :src="imageList[idx]"
                class="img_warp_Sss"
                :preview-src-list="imageList" />
    </div>

  </div>
</template>

<script>

export default {
  data () {
    return {
    }
  },
  props: {
    imageList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  mounted () {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.image_warp {
  display: flex;
  align-items: center;
  position: relative;
  overflow: auto;
  overflow-x: auto !important;
}
.img_warp_Sss {
  width: 200px !important;
  height: 200px;
  transition: all 0.3s;
  border-radius: 10px;
  margin-right: 10px;
}
</style>