<template>
  <div>
    <MyImage ref="ImageRef"
             :imageList="images"
             v-if="images.length"
             style="margin-bottom:10px;" />
    <Table ref="TablesRef"
           v-show="show"
           :tableData="tableData" />

    <toopic ref="toopicRef"
            :info="row"
            v-show="!show" />
  </div>
</template>

<script>
import MyImage from './image.vue'
import toopic from './toopic.vue'
export default {
  data () {
    return {
      show: true,
      row: {},
      tableData: [],
      pageName: 'paper_info_admin',
      // 题目编号,用户得分,题目分数,答题情况评价
      tableColumns: [
        { prop: 'paper_question_no', align: 'center', label: '题目编号', width: '', showToopic: false },
        { prop: 'user_score', align: 'center', label: '用户得分', width: '', showToopic: false },
        { prop: 'question_score', align: 'center', label: '题目分数', width: '', showToopic: false },
        { prop: 'comment_to_user', align: 'center', label: '答题情况评价', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '', type: 'text', btns: [
            { name: '查看题目和解答', class: 'text_btn_primary', event: 'showForm' },
          ]
        },
      ],
      images: [],
    }
  },
  components: {
    MyImage, toopic
  },
  mounted () {
    this.initData()
    this.setTablesColums()
  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData () {
      this.$http({
        url: '/api/v2/stats/paper_content',
        method: 'get',
        params: {
          user_paper_id: this.$route.query.user_paper_id
        }
      }).then(res => {
        this.images = res.data.images
        this.tableData = res.data.list
      })
    },
    showForm (row) {
      this.row = row
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>